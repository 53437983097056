
const tableWidth = window.innerWidth;
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  h,
  nextTick,
} from "@vue/runtime-core";
import {
  getOverviewData,
  sipoVlReject,
  sipoVlRelease,
  sipoBatchRelease,
  sipoBatchReject,
  getProviceItem,
  getCityItem,
  getBatchOpertionMsg,
} from "@/API/vl/dealerCommunication";
import setColumnWidth from "@/utils/rv/setColumnWidth";
import moment, { Moment } from "moment";
import downloadFile from "@/utils/claim/downloadFile";
import { EnumCheckStatus } from "@/views/VehicleLicense/types";
import BrandSelector from "@/views/RetailValidation/component/BrandSelector.vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement";
import useWait from "@/utils/payment/useWait";
const brandArray = [
  { text: "MB", value: "MB" },
  { text: "AMG", value: "AMG" },
  { text: "DENZA", value: "DENZA" },
  { text: "SMART", value: "SMART" },
  { text: "EQ", value: "EQ" },
  { text: "All", value: "全部" },
];
const dateTypeArray = ["Retail Date", "Registration Date", "Submission Date"];
const releasestatusArray = ["released", "unreleased"];
const submitModeArray = ["单车上传", "批量上传"];
const restrictedCityArray = ["限牌", "非限牌"];
const checkingStatusList = [
  { title: "SICCO Init", value: "SICCOInit" },
  { title: "SICCO Checked", value: "SICCOChecked" },
  { title: "SIPO Rejected", value: "SIPORejected" },
  { title: "Released", value: "Released" },
];
export default defineComponent({
  components: {
    BrandSelector,
    //NoticeModal,
  },
  setup() {
    const waitBrandDataLoaded = useWait();
    //let allBrands: string[] = [];
    const router = useRouter();
    const isOpen = ref(false);
    const current = ref(1);
    const size = ref(20);
    const modalVisible = ref(false);
    const visible = ref(false);
    const releaseVisible = ref(false);
    const rejectVisible = ref(false);
    const province = ref<any>();
    const city = ref<any>();
    const areaList = ref<any>([]);

    const vinNo = ref("");
    const vinNos = ref<string[]>([]);
    let searchListBrands: string[] = [];
    // 批量复制
    const batchQuery = reactive({
      batchQueryTitle: "",
      batchQueryModal: false,
      batchQueryListTitle: "",
    });
    const textContentNo = ref<string>("");
    const textNoArray = ref<string[]>([]);
    const input = ref();
    const batchPlaceholder = ref(["", "", "", "", "", ""]);

    const close = () => {
      isOpen.value = false;
    };

    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      textNoArray.value =
        cleanEmptyEleArray(vinNos.value)[0] === ""
          ? []
          : cleanEmptyEleArray(vinNos.value);
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ["", "", "", "", "", ""];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = "车架号批量查询";
      batchQuery.batchQueryListTitle = "车架号列表";
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName("list-content")[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      vinNo.value = vinNos.value[0];
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error("请从Excel中粘贴到文本框");
        textContentNo.value = "";
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ",")
          .split(",")
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName("list-content")[0];
      textContentNo.value = "";
      vinNos.value = textNoArray.value;
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      vinNos.value = [];
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };

    //获取省
    const getareaList = () => {
      getProviceItem().then((res: any): void => {
        areaList.value = res.data.map((item: string) => {
          return {
            value: item,
            label: item,
          };
        });
      });
    };

    onMounted(() => {
      areaList.value = getareaList();
    });

    //获取市
    const cityList = ref<any>();
    const getCityList = () => {
      if (province.value) {
        getCityItem(province.value).then((res: any) => {
          cityList.value = res.data.map((item: string) => {
            return {
              value: item,
              label: item,
            };
          });
        });
      }
    };

    watch(province, (next, prev) => {
      city.value = "";
      if (province.value) {
        getCityList();
      }
    });

    const columns = [
      { title: "Is Sampled", dataIndex: "isSampled", width: 100 },
      // { title: 'Region', dataIndex: 'region', width: 80 },
      { title: "Dealer Code", dataIndex: "dealerCode", width: 100 },
      {
        title: "SWT Code",
        dataIndex: "swtCode",
        align: "swtCode",
        width: 120,
      },
      { title: "Dealer Name", dataIndex: "dealerName", width: 100 },
      { title: "VIN", dataIndex: "vinNo", width: 168 },
      { title: "Plate No.", dataIndex: "plateNo", width: 90 },
      { title: "Registration Date", dataIndex: "regDate", width: 130 },
      { title: "Submission Date", dataIndex: "submitDate", width: 130 },
      { title: "Make", dataIndex: "make", width: 90 },
      { title: "Brand", dataIndex: "brand", width: 90 },
      { title: "Customer", dataIndex: "customer", width: 100 },
      { title: "Retail Type", dataIndex: "retailType", width: 104 },
      { title: "Restricted City", dataIndex: "restrictedCity", width: 110 },
      { title: "Retail Date", dataIndex: "retailDate", width: 130 },
      {
        title: "Engine No",
        width: 130,
        slots: { customRender: "engineNo" },
      },
      { title: "Model", dataIndex: "model", width: 130 },
      {
        title: "Revised by Dealer",
        width: 130,
        slots: { customRender: "isModified" },
      },
      {
        title: "Retail Validity",
        dataIndex: "retailValidate",
        width: 130,
        slots: { customRender: "retailValidity" },
      },
      {
        title: "Checking Status",
        dataIndex: "licenseCheckStatus",
        width: 130,
      },
      {
        title: "Submit Mode",
        width: 110,
        slots: { customRender: "submitMode" },
      },
      {
        title: "Check Result",
        width: 130,
        slots: { customRender: "checkResult" },
      },
      {
        title: "Check Date",
        width: 110,
        slots: { customRender: "checkDate" },
      },
      {
        title: "Checker",
        width: 130,
        slots: { customRender: "checker" },
      },

      {
        title: "SICCO Submit Date",
        width: 150,
        slots: { customRender: "siccoSubmitDate" },
      },
      {
        title: "Is Appeal",
        width: 120,
        dataIndex: "isAppeal",
      },
      {
        title: "Appeal Check Date",
        width: 150,
        slots: { customRender: "appealCheckDate" },
      },
      {
        title: "Appeal Checker",
        width: 130,
        slots: { customRender: "appealChecker" },
      },
      {
        title: "SICCO Resubmit Date",
        width: 160,
        slots: { customRender: "siccoCheckSubmitDate" },
      },
      {
        title: "Operation",
        fixed: "right",
        width: 180,
        slots: { customRender: "operation" },
      },
    ];
    const overviewListNum = reactive({
      siccoChecked: "",
      siccoCheckedAppeal: "",
      siccoUnchecked: "",
      siccoUncheckedAppeal: "",
      sipoReleased: "",
      sipoReleasedAppeal: "",
      sipoUnreleased: "",
      sipoUnreleasedAppeal: "",
      totalAppeal: "",
      totalSubmit: "",
    });
    const data = ref([]);
    const total = ref(0);
    const getDataL = () => {
      const gettoday = new Date();
      const yesterday = new Date(gettoday.getTime() - 24 * 60 * 60 * 1000);
      return yesterday;
    };

    //默认日期
    const getDataR = () => {
      const date: any = new Date();
      const givenMonth = date.getMonth();
      const newMonth = givenMonth - 5;
      date.setMonth(newMonth);
      date.setDate(1);
      return date;
    };
    const period = ref<Moment[]>([
      moment(getDataR(), "YYYY/MM/DD"),
      moment(getDataL(), "YYYY/MM/DD"),
    ]);
    const dateType = ref("Retail Date");
    const periodA = ref<Moment[]>([]);
    const appealDay = ref("7");

    //输入域
    const sipoSearch = reactive({
      brand: "",
      dealerCode: "",
      swtCode: "",
      releaseStatus: "",
      isAppeal: undefined as "Y" | "N" | undefined,
      submissionMode: "",
      restrictedCity: "",
      checkingStatus: [],
    });
    const sipoSearchList = reactive({
      brand: "",
      brandList: [] as string[],
      isAppeal: undefined as "Y" | "N" | undefined,
      city: "",
      dealerCode: "",
      swtCode: "",
      province: "",
      releaseStatus: "",
      regEndDate: "",
      regStartDate: "",
      retailEndDate: moment(period.value[1]).format("YYYY/MM/DD"),
      retailStartDate: moment(period.value[0]).format("YYYY/MM/DD"),
      submitEndDate: "",
      submitStartDate: "",
      countType: undefined as number | undefined,
      vinNo: "",
      vinList: [] as string[],
      submissionMode: "",
      restrictedCity: "",
      licenceCheckStatus: [],
    });

    //重置
    const resetEvent = () => {
      vinNo.value = "";
      vinNos.value = [];
      sipoSearch.brand = "全部";
      city.value = "";
      sipoSearch.dealerCode = "";
      sipoSearch.swtCode = "";
      province.value = "";
      sipoSearch.releaseStatus = "";
      sipoSearch.isAppeal = undefined;
      period.value = [
        moment(getDataR(), "YYYY/MM/DD"),
        moment(getDataL(), "YYYY/MM/DD"),
      ];
      periodA.value = [];
      dateType.value = "Retail Date";
      sipoSearch.submissionMode = "";
      sipoSearch.restrictedCity = "";
      sipoSearch.checkingStatus = [];
    };

    const countType = ref<number | undefined>(undefined);
    //获取参数值
    const getparams = () => {
      sipoSearchList.brand = sipoSearch.brand;
      sipoSearchList.isAppeal = sipoSearch.isAppeal;
      sipoSearchList.province = "";
      sipoSearchList.city = "";
      sipoSearchList.releaseStatus = sipoSearch.releaseStatus;
      sipoSearchList.dealerCode = "";
      sipoSearchList.swtCode = "";
      sipoSearchList.retailStartDate = "";
      sipoSearchList.retailEndDate = "";
      sipoSearchList.submitStartDate = "";
      sipoSearchList.submitEndDate = "";
      sipoSearchList.regStartDate = "";
      sipoSearchList.regEndDate = "";
      sipoSearchList.submissionMode = sipoSearch.submissionMode;
      sipoSearchList.restrictedCity = sipoSearch.restrictedCity;
      sipoSearchList.licenceCheckStatus = sipoSearch.checkingStatus;
      if (vinNos.value.length === 0) {
        sipoSearchList.vinNo = vinNo.value;
      }
      sipoSearchList.vinList = vinNos.value;
      sipoSearchList.countType = countType.value;
      if (province.value) {
        sipoSearchList.province = province.value;
      }
      if (city.value) {
        sipoSearchList.city = city.value;
      }
      if (sipoSearch.dealerCode !== "") {
        sipoSearchList.dealerCode = sipoSearch.dealerCode.trim();
      }
      if (sipoSearch.swtCode !== "") {
        sipoSearchList.swtCode = sipoSearch.swtCode.trim();
      }
      if (period.value.length > 0) {
        if (dateType.value == "Retail Date") {
          sipoSearchList.retailStartDate = moment(period.value[0]).format(
            "YYYY/MM/DD"
          );
          sipoSearchList.retailEndDate = moment(period.value[1]).format(
            "YYYY/MM/DD"
          );
        }
      }
      if (periodA.value.length > 0) {
        if (dateType.value == "Submission Date") {
          sipoSearchList.submitStartDate = moment(periodA.value[0]).format(
            "YYYY/MM/DD"
          );
          sipoSearchList.submitEndDate = moment(periodA.value[1]).format(
            "YYYY/MM/DD"
          );
        } else if (dateType.value == "Registration Date") {
          sipoSearchList.regStartDate = moment(periodA.value[0]).format(
            "YYYY/MM/DD"
          );
          sipoSearchList.regEndDate = moment(periodA.value[1]).format(
            "YYYY/MM/DD"
          );
        }
      }
      //  if (sipoSearch.submissionMode) {
      //   sipoSearchList.submissionMode = sipoSearch.submissionMode;
      // }
      //  if (sipoSearch.restrictedCity) {
      //   sipoSearchList.restrictedCity = sipoSearch.restrictedCity;
      // }
      //  if (sipoSearch.licenceCheckStatus) {
      //   sipoSearchList.licenceCheckStatus = sipoSearch.licenceCheckStatus;
      // }
      sipoSearchList["current"] = current.value;
      sipoSearchList["size"] = size.value;
      const paramData = sipoSearchList;
      return paramData;
    };

    //多维查询
    const getTableData = () => {
      const getparam = getparams();
      getparam.brandList = searchListBrands;
      getOverviewData(getparam).then((res: any) => {
        data.value = res.sipoCriteriaQueryRespVOS.content;
        overviewListNum.siccoChecked = res.sipoOverViewRespVO.siccoChecked;
        overviewListNum.siccoCheckedAppeal =
          res.sipoOverViewRespVO.siccoCheckedAppeal;
        overviewListNum.siccoUnchecked = res.sipoOverViewRespVO.siccoUnchecked;
        overviewListNum.siccoUncheckedAppeal =
          res.sipoOverViewRespVO.siccoUncheckedAppeal;
        overviewListNum.sipoReleased = res.sipoOverViewRespVO.sipoReleased;
        overviewListNum.sipoReleasedAppeal =
          res.sipoOverViewRespVO.sipoReleasedAppeal;
        overviewListNum.sipoUnreleased = res.sipoOverViewRespVO.sipoUnreleased;
        overviewListNum.sipoUnreleasedAppeal =
          res.sipoOverViewRespVO.sipoUnreleasedAppeal;
        overviewListNum.totalAppeal = res.sipoOverViewRespVO.totalAppeal;
        overviewListNum.totalSubmit = res.sipoOverViewRespVO.totalSubmit;
        total.value = res.sipoCriteriaQueryRespVOS.totalElements;
        const customerColumn = ["dealerName", "customer"];
        data.value.length &&
          Object.assign(
            columns,
            setColumnWidth(columns, data.value, customerColumn)
          );
      });
    };

    getTableData();

    //分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      current.value = page;
      size.value = pageSize;
      getTableData();
    };

    const sizeChange = (page: number, pageSize: number) => {
      current.value = page;
      size.value = pageSize;
      getTableData();
    };

    const dealerComEvent = () => {
      current.value = 1;
      getparams();
      getTableData();
      isOpen.value = false;
    };
    const handleBrandLoaded = (brands: string[]) => {
      //console.log(brands);
      searchListBrands = brands;
      waitBrandDataLoaded.toRun?.();
    };

    let vinNoReleased: any;
    let vinNoReject: any;
    let dealerCode: string;
    const rejectClickEvent = (record: any) => {
      vinNoReject = record.text.vinNo;
      dealerCode = record.record.dealerCode || "";
      rejectVisible.value = true;
    };
    //单车拒绝
    const rejectEvent = async () => {
      const params = {
        vinNo: vinNoReject,
        dealerCode,
        effectiveAppealDays: appealDay.value,
      };
      await sipoVlReject(params);
      rejectVisible.value = false;
      getTableData();
    };

    const releaseClickEvent = (record: any) => {
      vinNoReleased = record.record.vinNo;
      dealerCode = record.record.dealerCode || "";
      releaseVisible.value = true;
    };

    //单车发布
    const releaseEvent = () => {
      const params = {
        vinNo: vinNoReleased,
        dealerCode,
        effectiveAppealDays: appealDay.value,
      };
      sipoVlRelease(params);
      releaseVisible.value = false;
      getTableData();
    };

    const cancel = () => {
      releaseVisible.value = false;
      rejectVisible.value = false;
      appealDay.value = "7";
    };
    const cancelSingleEvent = () => {
      modalVisible.value = false;
      visible.value = false;
      appealDay.value = "7";
    };
    const approvedNo = ref(0);
    const rejectedNo = ref(0);
    const totalNo = ref(0);

    //批量发布
    const operReleaseEvent = () => {
      const releaseParams = { operationFlag: "release", ...getparams() };
      //(releaseParams as any).operationFlag = "release";
      getBatchOpertionMsg(releaseParams).then((res) => {
        if (typeof res != "string") {
          approvedNo.value = res.approvedNo;
          rejectedNo.value = res.rejectedNo;
          totalNo.value = res.totalNo;
          modalVisible.value = true;
        } else {
          message.error("Only unreleased records can be released");
        }
      });
    };

    //批量拒绝
    const operRejectEvent = async () => {
      const getParamItem = { operationFlag: "reject", ...getparams() };
      //(getParamItem as any).operationFlag = "reject";
      getBatchOpertionMsg(getParamItem).then((res) => {
        if (typeof res != "string") {
          approvedNo.value = res.approvedNo;
          rejectedNo.value = res.rejectedNo;
          totalNo.value = res.totalNo;
          visible.value = true;
        } else {
          message.error("Only unreleased records can be rejected");
        }
      });
    };
    //批量发布
    const releaseSingleEvent = async () => {
      const getReleaseParam = getparams();
      const effectiveAppealDays = appealDay.value;
      await sipoBatchRelease(effectiveAppealDays, getReleaseParam);
      modalVisible.value = false;
      getTableData();
    };
    //批量拒绝
    const rejectSingleEvent = async () => {
      const getRejectParam = getparams();
      const effectiveAppealDays = appealDay.value;
      await sipoBatchReject(effectiveAppealDays, getRejectParam);
      visible.value = false;
      getTableData();
    };

    const downLoad = () => {
      const getParam = getparams();
      const param = {
        url: "./rvapi/vl/sipo/criteriaQueryExport",
        method: "post",
        params: getParam,
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(param, "application/vnd-excel; char-set=UTF-8");
    };

    const dateTypeChange = (e: any) => {
      if (!e) {
        period.value = [];
        periodA.value = [];
      }
    };
    //? 点击数字查询数据
    const clickNumber = (type: number) => {
      countType.value = type;
      getTableData();
    };
    const gotoCheckingDetail = (record: any) => {
      sessionStorage.setItem("samplingCheckingVinNo", record.record.vinNo);
      sessionStorage.setItem(
        "samplingCheckingDealerCode",
        record.record.dealerCode
      );

      router.push({ path: "/vehiclelicense/siccoCheckingDetailView" });
    };
    return {
      gotoCheckingDetail,
      isOpen,
      columns,
      tableWidth,
      data,
      current,
      total,
      dateTypeArray,
      brandArray,
      releasestatusArray,
      submitModeArray,
      restrictedCityArray,
      checkingStatusList,
      sipoSearch,
      dealerComEvent,
      sipoSearchList,
      period,
      periodA,
      dateType,
      overviewListNum,
      province,
      getCityList,
      cityList,
      pageChange,
      sizeChange,
      rejectEvent,
      resetEvent,
      size,
      appealDay,
      modalVisible,
      visible,
      releaseVisible,
      rejectClickEvent,
      rejectVisible,
      releaseClickEvent,
      releaseEvent,
      releaseSingleEvent,
      rejectSingleEvent,
      cancel,
      cancelSingleEvent,
      operReleaseEvent,
      operRejectEvent,
      downLoad,
      getareaList,
      areaList,
      city,
      dateTypeChange,
      EnumCheckStatus,
      clickNumber,
      batchQuery,
      textContentNo,
      textNoArray,
      input,
      batchPlaceholder,
      setBatchQueryTextNoArray,
      setBatchPlaceholder,
      batchQueryEvent,
      closeBatchQueryModal,
      textChangEvent,
      clearBatchQuery,
      vinNo,
      vinNos,
      approvedNo,
      rejectedNo,
      totalNo,
      handleBrandLoaded,
    };
  },
});
